import { isGuniSms } from "constant/rolesAndAccess";
import { get, post, put, deleteRequest } from "utils/api";

export const getAllForms = async (params = {}) => {
  const response = await get(`/user/fill-verification-form`, params);
  return response.data.data;
};

export const getAllFormsById = async (id = "") => {
  const response = await get(`/user/fill-verification-form?verificationId=${id}`);
  return response.data.data;
};

export const submitFormStep = async (data, step) => {
  const response = await post(`/user/fill-verification-form?step=${step}`, data);
  return response.data;
};

export const sendForVerification = async (id = "", data) => {
  const response = await post(`/user/send-verification/${id}`, data);
  return response.data;
};

export const deleteVerificationForm = async (id = "") => {
  const response = await deleteRequest(`/user/delete-verification-form/${id}`);
  return response.data;
};

export const getVerificationForms = async (params = {}) => {
  const response = await get(`/${isGuniSms ? "admin" : "subadmin"}/verification-document`, params);
  return response.data.data;
};

export const verificationFormAction = async (params = {}) => {
  const response = await put(`/${isGuniSms ? "admin" : "subadmin"}/documentApprove`, params);
  return response.data;
};

export const uploadDocument = async data => {
  const response = await post(`/user/upload-document`, data);
  return response.data;
};

export const getUploadedDocument = async name => {
  const response = await get(`/user/get-document/${name}`);
  return response.data;
};
