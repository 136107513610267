import { isUndefined } from "lodash";
import { runInAction, decorate, observable } from "mobx";
import { getFilteredGatewaySMS, getGatewaySmsStats, getBulkListForApproval, approveBulk, getQuickMessagePendingList } from "service/gatewayService";
import { getGatewayMmsStats, getFilteredGatewayMMS } from "service/gatewayMmsService";
import { getAllNonActiveUsers, deleteNonActiveUser } from "service/userService";
import {
  getTotalSystemUsage,
  getUsersExpense,
  getTotalSystemPurchase,
  getProviderRoutes,
  deleteProviderRoutes,
  mapProviderRoute,
  updateProviderMapping,
  getProviderSQS,
  deleteProviderSQS,
  mapProviderSQS,
  updateProviderSQS,
  getAllProviders,
  getDefaultProvider,
  setDefaultProvider,
  getAdminList,
  registerAdmin
} from "service/adminService";
import moment from "moment";
import {
  activateDedicatedNum,
  addBulkDedicatedNums,
  addDedicatedNumber,
  dedicatedNumHistory,
  deleteDedicatedNumber,
  editDedicatedNumber,
  getAllDedicatedNo,
  getNumberPrice,
  releaseDedicationNumber
} from "service/dedicatedNumber";
import { getVerificationForms, verificationFormAction } from "service/userVerification";

class ObservableAdminGatewayStore {
  adminDashboardGatewaySMSList = [];
  adminDashboardGatewayMMSList = [];
  adminDashboardSmsStats = undefined;
  adminDashboardMmsStats = undefined;
  adminBulkListForApproval = [];
  bulkApprovalResponse = undefined;
  selectedBulk = undefined;
  selectedDedicatedNum = undefined;
  userDeleteResponse = undefined;
  dedicatedNumResponse = undefined;
  providerRouteResponse = undefined;
  usersList = [];
  total = 0;
  pageSize = 10;
  current = 1;
  search = "";
  statSearch = "";
  sortField = "createdAt";
  sortOrder = "descend";
  status = "";
  type = "sms";
  activeNum = "";
  numStatus = "";
  numType = "";
  numHistory = [];
  response = undefined;
  verificationFormList = [];
  verificationResponse = undefined;

  providerList = [];
  providerSQSList = [];
  defaultProvider = undefined;

  totalUsageData = undefined;
  totalPurchaseData = undefined;
  userTotalPurchaseData = undefined;
  totalUserExpenseData = undefined;
  selectedUserId = null;

  changeSorting = fieldObj => {
    runInAction(() => {
      const { field, sortOrder } = fieldObj;
      this.sortField = field;
      this.sortOrder = sortOrder;
    });
  };

  setSearch = search => {
    runInAction(() => {
      this.search = search;
      this.current = 1;
    });
  };

  setStatSearch = search => {
    runInAction(() => {
      this.statSearch = search;
    });
  };

  setPage = current => {
    runInAction(() => {
      this.current = current;
    });
  };

  setPageSize = pageSize => {
    runInAction(() => {
      this.pageSize = pageSize;
      this.current = 1;
    });
  };

  setMessageType = type => {
    runInAction(() => {
      this.type = type;
      this.current = 1;
    });
  };

  /**
   * get all Gateway SMS
   * @returns {Promise<[]>}
   */
  getSMSGatewayMessages = async args => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      pageSize: this.pageSize,
      ...(args && args)
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }
    const getAllGatewaySMSResponse = await getFilteredGatewaySMS(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getAllGatewaySMSResponse;
        this.adminDashboardGatewaySMSList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.adminDashboardGatewaySMSList;
  };
  /**
   * get all Gateway MMS
   * @returns {Promise<[]>}
   */
  getMMSGatewayMessages = async args => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      pageSize: this.pageSize,
      ...(args && args)
    };

    if (this.search) {
      for (var key of Object.keys(this.search)) {
        if (this.search[key]) {
          params[key] = this.search[key];
        }
      }
    }
    const getAllGatewayMMSResponse = await getFilteredGatewayMMS(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getAllGatewayMMSResponse;
        this.adminDashboardGatewayMMSList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }

    return this.adminDashboardGatewayMMSList;
  };

  /**
   * get all Gateway SMS STATS
   * @returns {Promise<[]>}
   */
  getGatewaySMSStats = async () => {
    const params = {
      search: this.statSearch
    };
    const getGatewayMessageStatsResponse = await getGatewaySmsStats(params);
    try {
      runInAction(() => {
        this.adminDashboardSmsStats = getGatewayMessageStatsResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.adminDashboardSmsStats;
  };

  /**
   * get all Gateway MMS STATS
   * @returns {Promise<[]>}
   */
  getGatewayMMSStats = async () => {
    const params = {
      search: this.statSearch
    };
    const getGatewayMessageStatsResponse = await getGatewayMmsStats(params);
    try {
      runInAction(() => {
        this.adminDashboardMmsStats = getGatewayMessageStatsResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.status = "error";
      });
    }
    return this.adminDashboardMmsStats;
  };

  selectBulk = bulkData => {
    this.selectedBulk = bulkData ? JSON.parse(JSON.stringify(bulkData)) : undefined;
  };

  /**
   * get all bulk list for approval
   * @returns {Promise<[]>}
   * @param {string} search
   */
  getBulkListForApproval = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      camp_type: this.type
    };
    if (this.search) params.search = this.search;
    const getBulkListForApprovalResponse = await getBulkListForApproval(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getBulkListForApprovalResponse;
        this.adminBulkListForApproval = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.adminBulkListForApproval = error;
      });
    }
    return this.adminBulkListForApproval;
  };

  /**
   * get quickmessage approval list
   */
  getQuickMessagePendingList = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize
    };
    if (this.search) params.search = this.search;
    const getMessageListResponse = await getQuickMessagePendingList(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = getMessageListResponse.data;
        this.adminBulkListForApproval = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.adminBulkListForApproval = error;
      });
    }
    return this.adminBulkListForApproval;
  };

  /**
   * approve/reject bulk
   * @returns {Promise<[]>}
   * @param {string} id
   * @param {string} status
   */
  approveBulk = async (id, stats) => {
    const approveBulkResponse = await approveBulk(id, stats);
    try {
      runInAction(() => {
        this.bulkApprovalResponse = approveBulkResponse;
        if (stats.camp_type !== "quicksms") {
          this.getBulkListForApproval();
        } else {
          this.getQuickMessagePendingList();
        }
      });
    } catch (error) {
      runInAction(() => {
        this.bulkApprovalResponse = error;
      });
    }
    return this.bulkApprovalResponse;
  };

  /**
   * get all non active users
   * @returns {Promise<[]>}
   * @param {string} params
   */
  getAllNonActiveUsers = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize
    };
    if (this.search) params.search = this.search;
    const usersResponse = await getAllNonActiveUsers(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = usersResponse;
        this.usersList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.usersList = error;
      });
    }
    return this.usersList;
  };

  /**
   * delete non active user
   * @returns {Promise<[]>}
   * @param {string} params
   */
  deleteNonActiveUser = async id => {
    const deleteResponse = await deleteNonActiveUser(id);
    try {
      runInAction(() => {
        this.userDeleteResponse = deleteResponse;
        this.getAllNonActiveUsers();
      });
    } catch (error) {
      runInAction(() => {
        this.userDeleteResponse = error;
      });
    }
    return this.userDeleteResponse;
  };

  /**
   * get total system purchase
   * @param {string} month , year
   * @returns {Promise<[]>}
   */
  getTotalSystemPurchase = async (args, id) => {
    let month = args.month || moment().month();
    let year = args.year || moment().year();
    let params = {
      month,
      year
    };
    if (id) params.userId = id;
    const purchaseResponse = await getTotalSystemPurchase(params);
    try {
      runInAction(() => {
        if (args.userId) {
          this.userTotalPurchaseData = purchaseResponse;
        } else {
          this.totalPurchaseData = purchaseResponse;
        }
      });
    } catch (error) {
      runInAction(() => {
        this.totalPurchaseData = error;
      });
    }
    return this.totalPurchaseData;
  };

  /**
   * get total system usage
   * @param {string} month , year
   * @returns {Promise<[]>}
   */
  getTotalSystemUsage = async (args, id) => {
    let month = args.month || moment().month() + 1;
    let year = args.year || moment().year();
    let params = {
      month,
      year
    };
    if (id) params.userId = id;
    const usageResponse = await getTotalSystemUsage(params);
    try {
      runInAction(() => {
        this.totalUsageData = usageResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.totalUsageData = error;
      });
    }
    return this.totalUsageData;
  };

  /**
   * get total user expense
   * @returns {Promise<[]>}
   * @param {string} userId
   * @param {string} month
   * @param {string} year
   */
  getTotalUserExpense = async args => {
    let month = args.month || moment().month() + 1;
    let year = args.year || moment().year();
    let params = {
      month,
      year,
      ...(args && args)
    };
    const expenseResponse = await getUsersExpense(params);
    try {
      runInAction(() => {
        this.totalUserExpenseData = expenseResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.totalUserExpenseData = error;
      });
    }
    return this.totalUserExpenseData;
  };

  selectUserDetails = id => {
    runInAction(() => {
      this.selectedUserId = id;
    });
  };

  //********** For Dedicated Numbers page *********//

  setActiveNum = active => {
    runInAction(() => {
      this.activeNum = active;
      this.current = 1;
    });
  };

  setNumType = type => {
    runInAction(() => {
      this.numType = type;
      this.current = 1;
    });
  };

  setStatus = status => {
    runInAction(() => {
      this.numStatus = status;
      this.current = 1;
    });
  };

  selectDedicatedNum = paramData => {
    this.selectedDedicatedNum = paramData ? JSON.parse(JSON.stringify(paramData)) : undefined;
  };

  /**
   * get all dedicated number list
   * @returns {Promise<[]>}
   * @param {string} params
   */
  getAllDedicatedNo = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      status: this.numStatus,
      active: this.activeNum,
      type: this.numType
    };
    if (this.search) params.search = this.search;
    const usersResponse = await getAllDedicatedNo(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = usersResponse;
        this.usersList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.usersList = error;
      });
    }
    return this.usersList;
  };

  /**
   * delete dedicated number
   * @returns {Promise<[]>}
   * @param {string} params
   */
  deleteDedicatedNumber = async id => {
    const deleteResponse = await deleteDedicatedNumber(id);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = deleteResponse;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  /**
   * add single dedicated number
   * @returns {Promise<[]>}
   * @param {string} params
   */
  addDedicatedNumber = async values => {
    const response = await addDedicatedNumber(values);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  /**
   * add bulk dedicated number
   * @returns {Promise<[]>}
   * @param {file} params
   */
  addBulkDedicatedNum = async data => {
    const response = await addBulkDedicatedNums(data);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  /**
   * edit dedicated number
   * @returns {Promise<[]>}
   * @param {string} params
   */
  editDedicatedNumber = async (id, values) => {
    const response = await editDedicatedNumber(id, values);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  /**
   * active/InActive Dedicated number
   * @returns {Promise<[]>}
   * @param {string} params
   */
  activateDedicatedNum = async id => {
    const response = await activateDedicatedNum(id);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  dedicatedNumHistory = async id => {
    const params = {
      sortField: this.sortField,
      sortOrder: this.sortOrder
    };
    params.limit = 50;
    const usersResponse = await dedicatedNumHistory(id, params);
    try {
      runInAction(() => {
        const { items } = usersResponse;
        this.numHistory = items;
      });
    } catch (error) {
      runInAction(() => {
        this.numHistory = error;
      });
    }
    return this.numHistory;
  };

  // get dedicated number price by product_id
  getNumberPrice = async id => {
    const response = await getNumberPrice(id);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  // release dedication no of user
  releaseDedicationNumber = async id => {
    const response = await releaseDedicationNumber(id);
    try {
      runInAction(() => {
        this.dedicatedNumResponse = response;
        this.getAllDedicatedNo();
      });
    } catch (error) {
      runInAction(() => {
        this.dedicatedNumResponse = error;
      });
    }
    return this.dedicatedNumResponse;
  };

  //*********** For User Verification Page *************//

  // get all submitted verification forms list
  getAllformsList = async () => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize
    };
    if (this.search) params.search = this.search;
    const response = await getVerificationForms(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = response;
        this.verificationFormList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.verificationFormList = error;
      });
    }
    return this.verificationFormList;
  };

  // admin action on verification form (approve or reject)
  verificationFormAction = async data => {
    const response = await verificationFormAction(data);
    try {
      runInAction(() => {
        this.verificationResponse = response;
        this.getAllformsList();
      });
    } catch (error) {
      runInAction(() => {
        this.verificationResponse = error;
      });
    }
    return this.verificationResponse;
  };

  // provider routes setting
  /**
   * @returns {Promise<[]>}
   * @param {string} params
   */
  getProviderRoutes = async (args = {}) => {
    const params = {
      page: this.current,
      sortField: this.sortField,
      sortOrder: this.sortOrder,
      limit: this.pageSize,
      ...args
    };
    if (this.search) params.search = this.search;
    const usersResponse = await getProviderRoutes(params);
    try {
      runInAction(() => {
        const { items, total, current, limit: pageSize } = usersResponse;
        this.usersList = items;
        this.pageSize = pageSize;
        this.current = current;
        this.total = total;
      });
    } catch (error) {
      runInAction(() => {
        this.usersList = error;
      });
    }
    return this.usersList;
  };

  deleteProviderRoutes = async (args = {}, id) => {
    const response = await deleteProviderRoutes(args, id);
    try {
      runInAction(() => {
        this.providerRouteResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerRouteResponse = error;
      });
    }
    return this.providerRouteResponse;
  };

  mapProviderRoute = async values => {
    const response = await mapProviderRoute(values);
    try {
      runInAction(() => {
        this.providerRouteResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerRouteResponse = error;
      });
    }
    return this.providerRouteResponse;
  };

  updateProviderMapping = async (values, id) => {
    const response = await updateProviderMapping(id, values);
    try {
      runInAction(() => {
        this.providerRouteResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerRouteResponse = error;
      });
    }
    return this.providerRouteResponse;
  };
  // provider
  /**
   * @returns {Promise<[]>}
   * @param {string} params
   */
  getProviderSQS = async (args = {}) => {
    const params = {
      // page: this.current,
      // sortField: this.sortField,
      // sortOrder: this.sortOrder,
      // limit: this.pageSize,
      ...args
    };
    if (this.search) params.search = this.search;
    const usersResponse = await getProviderSQS(params);
    try {
      runInAction(() => {
        // const { items, total, current, limit: pageSize } = usersResponse;
        // this.usersList = items;
        // this.pageSize = pageSize;
        // this.current = current;
        // this.total = total;
        this.providerSQSList = usersResponse;
      });
    } catch (error) {
      runInAction(() => {
        this.providerSQSList = error;
      });
    }
    return this.providerSQSList;
  };

  deleteProviderSQS = async (args = {}, id) => {
    const response = await deleteProviderSQS(args, id);
    try {
      runInAction(() => {
        this.providerRouteResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerRouteResponse = error;
      });
    }
    return this.providerRouteResponse;
  };

  mapProviderSQS = async values => {
    const response = await mapProviderSQS(values);
    try {
      runInAction(() => {
        this.providerRouteResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerRouteResponse = error;
      });
    }
    return this.providerRouteResponse;
  };

  updateProviderSQS = async (values, id) => {
    const response = await updateProviderSQS(id, values);
    try {
      runInAction(() => {
        this.providerRouteResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerRouteResponse = error;
      });
    }
    return this.providerRouteResponse;
  };

  // get providers list
  getAllProviders = async (args = {}) => {
    const response = await getAllProviders(args);
    try {
      runInAction(() => {
        this.providerList = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerList = error;
      });
    }
    return this.providerList;
  };

  // get default provider
  getDefaultProvider = async (args = {}) => {
    const response = await getDefaultProvider(args);
    try {
      runInAction(() => {
        this.defaultProvider = response?.data || {};
      });
    } catch (error) {
      runInAction(() => {
        this.defaultProvider = undefined;
      });
    }
    return this.defaultProvider;
  };

  // set default provider
  setDefaultProvider = async (args = {}) => {
    const response = await setDefaultProvider(args);
    try {
      runInAction(() => {
        this.providerRouteResponse = response;
      });
    } catch (error) {
      runInAction(() => {
        this.providerRouteResponse = error;
      });
    }
    return this.providerRouteResponse;
  };

  getAdminList = async (args = {}) => {
    const response = await getAdminList(args);
    try {
      runInAction(() => {
        this.usersList = response;
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.usersList;
  };

  registerAdmin = async (args = {}) => {
    const response = await registerAdmin(args);
    try {
      runInAction(() => {
        this.response = response;
        if (response.status) {
          this.getAdminList();
        }
      });
    } catch (error) {
      runInAction(() => {
        this.response = error;
      });
    }
    return this.response;
  };
}

decorate(ObservableAdminGatewayStore, {
  adminDashboardGatewaySMSList: observable,
  adminDashboardGatewayMMSList: observable,
  adminDashboardSmsStats: observable,
  adminDashboardMmsStats: observable,
  adminBulkListForApproval: observable,
  bulkApprovalResponse: observable,
  selectedBulk: observable,
  usersList: observable,
  userDeleteResponse: observable,
  dedicatedNumResponse: observable,
  selectedDedicatedNum: observable,
  activeNum: observable,
  numStatus: observable,
  numType: observable,
  pageSize: observable,
  current: observable,
  total: observable,
  sortField: observable,
  sortOrder: observable,
  search: observable,
  type: observable,

  providerSQSList: observable,
  providerList: observable,

  verificationFormList: observable,

  totalPurchaseData: observable,
  userTotalPurchaseData: observable,
  totalUsageData: observable,
  totalUserExpenseData: observable,
  selectedUserId: observable
});
export default new ObservableAdminGatewayStore();
