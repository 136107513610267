// old store
import ObservablePhoneStore from "./phone/phoneStore";
import ObservableSchedulerStore from "./scheduler/SchedulerStore";
import ObservableUserStore from "./user/UserStore";
import ObservableAuthStore from "./auth/AuthStore";
import ObservableMessageStoreOld from "./message/MessageStoreOld";
import ObservableRoomStore from "./room/RoomStore";
import ObservableGroupStore from "./group/GroupStore";
import ObservableBulkStore from "./bulk/BulkStore";
import ObservableBlockStore from "./block/BlockStore";
import ObservableTemplateStore from "./template/TemplateStore";
import ObservableParamStore from "./param/ParamStore";
import ObservableDeviceStore from "./device/DeviceStore";
import ObservableGatewayStore from "./gateway/gatewayStore";
import ObservableGatewayMMSStore from "./gatewayMms/gatewayMmsStore";
import ObservableContactStore from "./contact/contactStore";
import ObservableOptOutStore from "./optout/OptOutStore";
import ObservableGatewayBulkStore from "./gatewayBulk/GatewayBulkStore";
import ObservableReplyStore from "./reply/replyStore";
import ObservableAdminGatewayStore from "./adminGateway/AdminGatewayStore";
import ObservableFileSchedulerStore from "./fileScheduler/FileSchedulerStore";
import ObservableIncomingStore from "./incoming/incomingStore";
import ObservableSharedNumberStore from "./sharedNumber/SharedNumber";
import ObservableEmailSmsStore from "./emailsms/EmailSmsStore";
import ObservableContactFormStore from "./contactForm/contactFormStore";

// new store
import ObservableSupportStore from "./support/supportStore";
import ObservableCategoryStore from "./category/categoryStore";
import ObservableModalStore from "./modals/ModalStore";
import ObservableMessageStore from "./message/MessageStore";
import ObservableShortUrlStore from "./shorturl/shorturlStore";
import ObservableWorkFlowStore from "./workflow/WorkFlowStore";
import ObservableSubAccountStore from "./subAccount/subAccountStore";
import ObservableDashboardtStore from "./dashboardGraph/dashboardGraphStore";
import ObservableDedicatedNumStore from "./dedicatedNumber/dedicatedNumStore";
import ObservableUserVerificationStore from "./userVerifications/userVerificationStore";
import ObservableBusinessNameStore from "./businessName/businessNameStore";
import ObservablePersonalNumberStore from "./personalNumber/personalNumberStore";
import ObservableIntegrationStore from "./integration/integrationStore";
import ObservableOrganizationStore from "./organization/organizationStore";

class RootStore {
  constructor() {
    // old store
    this.groupStore = ObservableGroupStore;
    this.phoneStore = ObservablePhoneStore;
    this.schedulerStore = ObservableSchedulerStore;
    this.userStore = ObservableUserStore;
    this.authStore = ObservableAuthStore;
    this.messageStoreOld = ObservableMessageStoreOld;
    this.roomStore = ObservableRoomStore;
    this.bulkStore = ObservableBulkStore;
    this.blockStore = ObservableBlockStore;
    this.templateStore = ObservableTemplateStore;
    this.paramStore = ObservableParamStore;
    this.deviceStore = ObservableDeviceStore;
    this.gatewayStore = ObservableGatewayStore;
    this.gatewayMmsStore = ObservableGatewayMMSStore;
    this.contactStore = ObservableContactStore;
    this.optOutStore = ObservableOptOutStore;
    this.gatewayBulkStore = ObservableGatewayBulkStore;
    this.replyStore = ObservableReplyStore;
    this.adminGatewayStore = ObservableAdminGatewayStore;
    this.fileSchedulerStore = ObservableFileSchedulerStore;
    this.incomingStore = ObservableIncomingStore;
    this.sharedNumberStore = ObservableSharedNumberStore;
    this.emailSmsStore = ObservableEmailSmsStore;
    this.contactFormStore = ObservableContactFormStore;

    // new store
    this.modalStore = ObservableModalStore;
    this.messageStore = ObservableMessageStore;
    this.supportStore = ObservableSupportStore;
    this.categoryStore = ObservableCategoryStore;
    this.shorturlStore = ObservableShortUrlStore;
    this.workflowStore = ObservableWorkFlowStore;
    this.subAccountStore = ObservableSubAccountStore;
    this.dashboardGraphStore = ObservableDashboardtStore;
    this.dedicatedNumStore = ObservableDedicatedNumStore;
    this.userVerificationStore = ObservableUserVerificationStore;
    this.businessNameStore = ObservableBusinessNameStore;
    this.personalNumberStore = ObservablePersonalNumberStore;
    this.integrationStore = ObservableIntegrationStore;
    this.organizationStore = ObservableOrganizationStore;
  }
}

export default RootStore;
