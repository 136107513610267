import { runInAction, decorate, observable } from "mobx";
import { registerUser } from "service/organizationService";

class UserRegistrationStore {
  registrationResponse = undefined;
  isSubmitting = false;

  registerUser = async formData => {
    try {
      runInAction(() => {
        this.isSubmitting = true;
      });

      const response = await registerUser(formData);

      runInAction(() => {
        this.registrationResponse = response;
        this.isSubmitting = false;
      });

      return this.registrationResponse;
    } catch (error) {
      runInAction(() => {
        this.registrationResponse = error;
        this.isSubmitting = false;
      });
      return this.registrationResponse;
    }
  };
}

decorate(UserRegistrationStore, {
  registrationResponse: observable,
  isSubmitting: observable
});

export default new UserRegistrationStore();
