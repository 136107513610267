import { isGuniSms } from "constant/rolesAndAccess";
import { get, post, put, deleteRequest } from "utils/api";

export const videoCompression = async data => {
  const response = await post(`/gatewaymms/video-compress`, data);
  return response.data;
};

/**
 * Add new user api
 * @returns {Promise<any>}
 */
export const getUser = async () => {
  const addResponse = await get(`/user/me`);
  return addResponse.data;
};

export const getUserByContact = async email => {
  const getResponse = await post(`/auth/userByEmail`, email);
  return getResponse.data;
};

export const getMyBalance = async () => {
  const response = await get(`/user/balance`);
  return response.data.data;
};

export const googleLogin = async data => {
  const googleResponse = await post(`/auth/google`, data);
  return googleResponse.data;
};

export const faceBookLogin = async data => {
  const facebookResponse = post(`/auth/facebook`, data);
  return facebookResponse.data;
};

export const SignUpUser = async (signUpData = {}) => {
  const addResponse = await post(`/auth/newsignup`, signUpData);
  return addResponse.data;
};

// not in use
export const additionalUserInfo = async (data = {}) => {
  const addResponse = await post(`/auth/additional-info`, data);
  return addResponse.data.data;
};
export const resendSignUpMail = async (data = {}) => {
  const addResponse = await post(`/auth/resend-email`, data);
  return addResponse.data;
};
export const verifySignUpMail = async (data = {}) => {
  const addResponse = await post(`/auth/verify-email`, data);
  return addResponse.data;
};

export const forgotPassword = async (data = {}) => {
  const addResponse = await post(`/auth/newforgotPassword`, data);
  return addResponse.data;
};

export const resetPassword = async (data = {}) => {
  const addResponse = await post(`/auth/new-reset-password`, data);
  return addResponse.data;
};

export const changePassword = async (data = {}) => {
  const addResponse = await post(`/user/new-change-password`, data);
  return addResponse.data;
};

export const confirmEmail = async (data = {}) => {
  const addResponse = await post(`/auth/signup-confirm`, data);
  return addResponse.data;
};

/**
 * Add new user api
 * @param loginData
 * @returns {Promise<any>}
 */
export const loginUser = async (loginData = {}) => {
  const addResponse = await post(`/auth/newlogin`, loginData);
  return addResponse.data;
};

/**
 * Add new user api
 * @param loginData
 * @returns {Promise<any>}
 */
export const mfaEmail = async (loginData = {}) => {
  const addResponse = await post(`/auth/mfa-email-login`, loginData);
  return addResponse.data;
};

export const phoneLogin = async params => {
  const phoneLoginRespose = await post(`/auth/loginwithmobile`, params);
  return phoneLoginRespose.data;
};

/**
 * Call get all user api
 * @returns {Promise<any>}
 */
export const getAllUsers = async params => {
  // save api response for Users List list
  const UsersListResponse = await get(`/${isGuniSms ? "admin" : "subadmin"}/user`, params);
  return UsersListResponse.data;
};

/**
 * Add new user api
 * @param userData
 * @returns {Promise<any>}
 */
export const addUser = async (userData = {}) => {
  const addResponse = await post(`/${isGuniSms ? "admin" : "subadmin"}/user`, userData);
  return addResponse.data;
};

/**
 * Update existing user Api
 * @param userData
 * @param userId
 * @returns {Promise<any>}
 */
export const updateUser = async (userData = {}, userId) => {
  const updateResponse = await put(`/${isGuniSms ? "admin" : "subadmin"}/user/${userId}`, userData);
  return updateResponse.data;
};

/**
 * Delete user
 * @param UsersId
 * @returns {Promise<any>}
 */
export const deleteUser = async UsersId => {
  const deleteResponse = await deleteRequest(`/${isGuniSms ? "admin" : "subadmin"}/user/${UsersId}`);
  return deleteResponse.data;
};

export const addCredit = async (UsersId, credit) => {
  const deleteResponse = await put(`/${isGuniSms ? "admin" : "subadmin"}/user/credit/${UsersId}`, credit);
  return deleteResponse.data;
};

export const createAppToken = async data => {
  const deleteResponse = await post(`/user/token`, data);
  return deleteResponse.data;
};

export const getAppToken = async () => {
  const deleteResponse = await get(`/user/token`);
  return deleteResponse.data;
};

export const deleteAppToken = async tokenId => {
  const deleteResponse = await deleteRequest(`/user/token/${tokenId}`);
  return deleteResponse.data;
};

export const getAllPlans = async () => {
  const deleteResponse = await get(`/user/plans`);
  return deleteResponse.data;
};

export const getUsage = async params => {
  const usageResponse = await get(`/user/usage`, params);
  return usageResponse.data;
};

export const subscribeToPlan = async params => {
  const deleteResponse = await post(`/user/subscribe`, params);
  return deleteResponse.data;
};

export const addWebHook = async body => {
  const deleteResponse = await post(`/user/webhook`, body);
  return deleteResponse.data;
};

//GATEWAY PLANS --->

export const getAllGatewayPlans = async () => {
  const gatewayPlansResponse = await get(`/user/gatewayplans`);
  return gatewayPlansResponse.data.data;
};

export const getGatewayUsage = async params => {
  const usageGatewayResponse = await get(`/user/gatewayusage`, params);
  return usageGatewayResponse.data.data;
};

export const getMmsGatewayUsage = async params => {
  const usageGatewayResponse = await get(`/user/gatewayMmsusage`, params);
  return usageGatewayResponse.data.data;
};
export const updateGatewayCredit = async (UsersId, params) => {
  const updateGatewayCreditResponse = await put(`/${isGuniSms ? "admin" : "subadmin"}/user/gatewaycredit/${UsersId}`, params);
  return updateGatewayCreditResponse.data;
};

export const updateUserLogo = async params => {
  const updateUserLogoResponse = await post(`/user/logo`, params);
  return updateUserLogoResponse.data;
};

export const updateProfileConfig = async params => {
  const updateProfileConfigResponse = await post(`/user/profileconfig`, params);
  return updateProfileConfigResponse.data;
};
export const updateMe = async params => {
  const updateMeResponse = await put(`/user/me`, params);
  return updateMeResponse.data;
};

export const deleteMe = async params => {
  const deleteMeResponse = await deleteRequest(`/user/me/`, params);
  return deleteMeResponse.data;
};

export const generateOTP = async params => {
  const generateOTPResponse = await post(`/auth/generate-otp`, params);
  return generateOTPResponse.data;
};

export const sendChangeMobileOTP = async data => {
  const generateOTPResponse = await post(`/auth/change-mobile`, data);
  return generateOTPResponse.data;
};

export const checkOTPRequirement = async params => {
  const checkOTPRequirementResponse = await post(`/auth/otprequirement`, params);
  return checkOTPRequirementResponse.data;
};

export const sendMobileOTP = async params => {
  const sendMobileOTPResponse = await post(`/auth/sendOtp`, params);
  return sendMobileOTPResponse.data;
};

export const verifyMobileOTP = async params => {
  const verifyOTPResponse = await post(`/auth/mobile/verifyotp`, params);
  return verifyOTPResponse.data;
};

export const verifyMobileForSignUp = async params => {
  const verifyOTPResponse = await post(`/auth/mobile/verifyotp`, params);
  return verifyOTPResponse.data;
};

export const verifySignupOtp = async params => {
  const verifyOTPResponse = await post(`/auth/newverifyotp`, params);
  return verifyOTPResponse.data;
};

export const verifyCoupon = async params => {
  const verifyCouponResponse = await post(`/subscribe/verifycoupon`, params);
  return verifyCouponResponse.data;
};

//super admin: add special plan for a user
export const addSpecialPlan = async ({ user, centsPerCredit }) => {
  const response = await post(`/special-plan`, {
    user,
    centsPerCredit
  });
  return response.data;
};

export const getSpecialPlansByUser = async ({ userId }) => {
  const response = await get(`/special-plan`, {
    userId
  });
  return response.data;
};

export const deleteSpecialPlan = async ({ userId, planId }) => {
  const response = await deleteRequest(`/special-plan`, {
    userId,
    planId
  });
  return response.data;
};

export const customPayment = async params => {
  const customPaymentResponse = await post(`/user/customPayment`, params);
  return customPaymentResponse;
};

//user own special plans
export const getMySpecialPlans = async () => {
  const response = await get(`/special-plan/me`);
  return response.data;
};

export const fetchCurrencies = async () => {
  const response = await get(`/${isGuniSms ? "admin" : "subadmin"}/currency`);
  return response.data;
};

export const addPurchaseOrder = async data => {
  const { user, totalAmount, currency, isSMSAllow, isMMSAllow, centsPerSMS, centsPerMMS } = data;
  const response = await post(`/${isGuniSms ? "admin" : "subadmin"}/userPurchaseOrder`, {
    user,
    totalAmount,
    currency,
    isSMSAllow,
    isMMSAllow,
    centsPerSMS,
    centsPerMMS
  });
  return response.data;
};

export const getUserAllPurchaseOrders = async data => {
  const { user: userToSearch } = data;
  const response = await get(`/${isGuniSms ? "admin" : "subadmin"}/userPurchaseOrder`, { userToSearch });
  return response.data.data;
};

export const deletePurchaseOrder = async data => {
  const { userToSearch, orderId } = data;
  const response = await deleteRequest(`/${isGuniSms ? "admin" : "subadmin"}/userPurchaseOrder`, {
    userToSearch,
    orderId
  });
  return response.data;
};

export const updatePurchaseOrder = async data => {
  const response = await put(`/${isGuniSms ? "admin" : "subadmin"}/userPurchaseOrder`, data);
  return response.data;
};

export const getCards = async () => {
  const response = await get(`/user/getCards`);
  return response.data;
};

//File upload

export async function uploadFile(formData) {
  return post(`/fileStorage/uploadFile`, formData);
}

export async function fetchUploadedFiles(query) {
  return get(`/fileStorage/getFiles`, query);
}

// Invoice

export const getInvoices = async data => {
  const getInvoicesResponse = await get(`/purchase-order`, data);
  return getInvoicesResponse.data;
};

// Dashboard Steps API

export const getDashboardStepsData = async () => {
  const getDashboardStepsResponse = await get(`/user/getUserdashdata`);
  return getDashboardStepsResponse.data;
};

export const getUserLoginInformation = async userId => {
  const getUserLoginInformation = await get(`/${isGuniSms ? "admin" : "subadmin"}/information/${userId}`);
  return getUserLoginInformation.data;
};

export const loginAsUser = async user => {
  const loginAsUserResponse = await post(`/${isGuniSms ? "admin" : "subadmin"}/loginAsUser`, user);
  return loginAsUserResponse.data.data;
};

export const setAutoCredit = async values => {
  const autoCreditResponse = await put(`/user/autoCredit`, values);
  return autoCreditResponse.data;
};

export const signUpByGoogle = async values => {
  const signupgoogleResponse = await post(`/auth/signUpByGoogle`, values);
  return signupgoogleResponse.data;
};

//
export const loginGoogle = async values => {
  const addResponse = await post(`/auth/loginByGoogle`, values);
  return addResponse.data.data;
};

export const resendOTP = async values => {
  const resendOTP = await post(`/auth/resendMobileOTP`, values);
  return resendOTP.data;
};

/**
 * @description THis function is used for checking this function already exists or not.
 */

export const checkFacebook = async values => {
  const isFaceBook = await post(`/auth/checkFacebook`, values);
  return isFaceBook.data.data;
};

// reqst otp for delete account
export const deleteAccountOTP = async message => {
  const response = await post(`/user/deleteUserEmail`, message);
  return response.data;
};

// get non active usersList
export const getAllNonActiveUsers = async params => {
  const response = await get(`/${isGuniSms ? "admin" : "subadmin"}/get-deactive-users`, params);
  return response.data.data;
};

// delete non active user
export const deleteNonActiveUser = async id => {
  const response = await deleteRequest(`/${isGuniSms ? "admin" : "subadmin"}/delete-deactive-users/${id}`);
  return response.data;
};

/**
 * MFA verification
 * @param otp
 * @returns {Promise<any>}
 */
export const mfaVerification = async opt => {
  const response = await post(`/auth/verifyLoginOTP`, opt);
  return response.data;
};

/**
 * Resend MFA verification
 * @param otp
 * @returns {Promise<any>}
 */
export const resendMfaVerificationCode = async email => {
  const response = await post(`/auth/newresendLoginOTP`, email);
  return response.data;
};

export const resendSignupOtp = async email => {
  const response = await post(`/auth/new-resend`, email);
  return response.data;
};

// current rate of sms and mms
export const userCurrentRate = async () => {
  const response = await get(`/user/getCurrentbalance`);
  return response.data;
};

// update google sheet url in user profile
export const updateGoogleSheetId = async data => {
  const response = await post(`/user/googlesheetconfig`, data);
  return response.data;
};

// delete saved card
export const deleteCard = async data => {
  const response = await post(`/user/deleteCard`, data);
  return response.data;
};

// Buy Product Bundle with ID
export const buyProductBundle = async data => {
  const response = await post(`/user/purchase-bundle`, data);
  return response.data;
};

// reveal token auth for user
export const revealTokenAuth = async id => {
  const response = await post(`/user/send-token-otp/${id}`);
  return response.data;
};

export const revealToken = async param => {
  const response = await get(`/user/view-token`, param);
  return response.data;
};

export const getUserDocument = async name => {
  const response = await get(`/template/mms-image/${name}`);
  return response.data;
};

export const mfaEmailList = async data => {
  const response = await put(`/user/set-up-multiple-mfa-email`, data);
  return response.data;
};
